<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div>
          <h4 class="mb-0">
            {{ $t('Adsense incomes') }}
          </h4>
        </div>
      </div>
      <hr>
      <b-table
        id="adsense_incomes_table"
        striped
        :items="rows"
        :fields="fields"
        :current-page="serverParams.page"
        :per-page="serverParams.limit"
        responsive="sm"
      >
        <template #cell(report_job_id)="data">
          <router-link :to="{name: 'adsense-incomes-details', params: {id: data.item.report_job_id }}">
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(datetime_uploaded)="data">
          {{ getFormattedDate(new Date(data.value), true) }}
        </template>
        <template #cell(adsense_name)="data">
          {{ data.value }}<br>
          <small>{{ data.item.adsense_user }}</small>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          :label="$t('message.pagelength')"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-class="align-self-center"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="serverParams.limit"
            size="sm"
            inline
            :options="pageOptions"
            class="ml-25"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="serverParams.page"
            :total-rows="totalRows"
            :per-page="serverParams.limit"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getFormattedDate from '@/utils/date-formatter'

import {
  BCard,
  BCardBody,
  BTable,
  BPagination,
  BFormGroup,
  BFormSelect,
} from 'bootstrap-vue'

export default {
  name: 'AdsenseIncomesTable',
  components: {
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BFormGroup,
    BFormSelect,
  },
  data() {
    return {
      rows: [],
      fields: [
        { key: 'report_job_id', label: 'Report job id' },
        { key: 'datetime_uploaded', label: 'Date uploaded' },
        { key: 'publisher_network_name', label: 'Publisher network' },
        { key: 'adsense_name', label: 'Adsense user' },
      ],
      pageOptions: [1, 5, 10],
      serverParams: {
        page: 1,
        limit: 10,
      },
      totalRows: 0,
      getFormattedDate,
    }
  },
  created() {
    this.fetchAdsenseIncomes()
  },
  methods: {
    async fetchAdsenseIncomes() {
      await useJwt.getAdsenseIncomes({ ...this.serverParams })
        .then(response => {
          this.rows = response.data
          this.totalRows = response.data.length
          this.$store.dispatch('dashboard-incomes/setAdsenseIncomes', response.data)
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors[0].detail,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
