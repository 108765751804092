<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div>
          <h4 class="mb-0">
            Import
          </h4>
        </div>
        <feather-icon
          icon="XIcon"
          size="18"
          class="cursor-pointer"
          @click="$router.go(-1)"
        />
      </div>
      <hr>
      <div class="d-flex justify-content-between mb-1">
        <div>
          <b-form-file
            ref="refInputEl"
            accept=".csv"
            :hidden="true"
            plain
            @input="uploadAdsenseIncomes($refs.refInputEl.files)"
          />

          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="$refs.refInputEl.$el.click()"
          >
            <feather-icon
              icon="UploadIcon"
              size="16"
            />
            {{ $t('adsense-incomes.upload') }}
          </b-button>
        </div>
      </div>
      <template v-if="editableRows.length > 0">
        <div
          v-if="Object.keys(getImportIncomes).length > 0"
          class="alert alert-success"
        >
          <div class="alert-body">
            <div
              v-for="infoField in Object.keys(getImportIncomes)"
              :key="infoField"
            >
              <div
                v-if="infoField !== 'rows'"
              >
                {{ metaInfoTextKeys[infoField] }}: {{ getImportIncomes[infoField] }}
              </div>
            </div>
          </div>
        </div>
        <b-table
          id="adsense_incomes"
          ref="selectableTable"
          selectable
          select-mode="multi"
          striped
          foot-clone
          :items="editableRows"
          :fields="fields"
          responsive
          @row-selected="onRowSelected"
          @row-clicked="onRowClick"
        >
          <template
            #cell(edit)="row"
          >
            <feather-icon
              icon="EditIcon"
              size="20"
              @click.stop="row.toggleDetails"
            />
          </template>
          <template #row-details="row">
            <b-card>
              <b-form-group
                :label="$t('adsense-incomes.site_income')"
                label-for="i-income"
              >
                <b-form-input
                  :value="row.item.site_income"
                  @input="changeSiteIncome($event, row)"
                />
              </b-form-group>
            </b-card>
          </template>

          <template #head(check)>
            <b-form-checkbox
              v-model="selectAll"
              @change="toggleSelected"
            />
          </template>
          <template #head(date)>
            {{ $t('adsense-incomes.date') }}
          </template>
          <template #head(impressions)>
            {{ $t('adsense-incomes.impressions') }}
          </template>
          <template #head(clicks)>
            {{ $t('adsense-incomes.clicks') }}
          </template>

          <template #cell(check)="data">
            <feather-icon
              :class="data.item.ok === true ? 'checked' : 'unchecked'"
              :icon="data.item.ok === true ? 'CheckCircleIcon' : 'CircleIcon'"
              size="20"
            />
          </template>
          <template #cell(site_domain)="data">
            {{ data.value }}
          </template>
          <template #cell(revenue)="data">
            {{ data.value ? currency('USD', data.value) : 0 }}
          </template>
          <template #cell(income)="data">
            {{ data.value ? currency('USD', data.value) : 0 }}
          </template>
          <template #cell(site_income)="data">
            {{ data.value ? currency(data.item.site_currency, data.value) : 0 }}
          </template>
          <template #cell(phoenix_revenue)="data">
            {{ calcPhoenixRevenue(data.item) }}
          </template>
          <template #cell(notes)="data">
            <feather-icon
              v-if="data.value !== ''"
              v-b-tooltip.hover.top="data.value"
              icon="InfoIcon"
              size="18"
              class="toggle-close cursor-pointer text-danger"
              @click="$router.go(-1)"
            />
          </template>

          <template #foot(check)="data">
            {{ data.emptyText }}
          </template>
          <template #foot(phoenix_site_domain)="data">
            {{ data.emptyText }}
          </template>
          <template #foot(date)="data">
            {{ data.emptyText }}
          </template>
          <template #foot(clicks)="data">
            <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalAmount('clicks')) }}</strong>
          </template>
          <template #foot(impressions)="data">
            <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalAmount('impressions')) }}</strong>
          </template>
          <template #foot(notes)="data">
            {{ data.emptyText }}
          </template>
          <template #foot(edit)="data">
            {{ data.emptyText }}
          </template>
          <template #foot(revenue)>
            <strong>{{ currency('USD', totalAmount('revenue')) }}</strong>
          </template>
          <template #foot(income)>
            <strong>{{ currency('USD', totalAmount('income')) }}</strong>
          </template>
          <template
            v-if="totals.length !== 0"
            #foot(site_income)="data"
          >
            <div
              v-for="key in Object.keys(totals)"
              :key="key"
            >
              <strong :data-label="data.label">{{ countCurencyTotal(key, 'site_income') }}</strong>
            </div>
          </template>
          <template
            v-if="totals.length !== 0"
            #foot(phoenix_revenue)
          >
            {{ calcPhoenixRevenueTotal }}
          </template>
        </b-table>

        <b-button
          v-if="editableRows.length > 0"
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="success"
          class="btn-icon"
          @click="approveIncomes()"
        >
          <feather-icon
            icon="LikeIcon"
            size="16"
          />
          {{ $t('adsense-incomes.approve') }}
        </b-button>
      </template>
    </b-card>

    <AdsenseIncomesTable
      v-if="editableRows.length === 0"
    />
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'
import {
  BCard,
  BButton,
  BFormFile,
  BFormGroup,
  BFormInput,
  BTable,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import { mapGetters } from 'vuex'
import AdsenseIncomesTable from '@/views/dashboard/adsense-incomes/AdsenseIncomesTable.vue'
import currencyFormatter from '@/utils/currency-formatter'

export default {
  name: 'AdsenseIncomes',
  components: {
    Breadcrumbs,
    BCard,
    BButton,
    BFormGroup,
    BFormFile,
    BFormInput,
    BTable,
    BFormCheckbox,
    AdsenseIncomesTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      currency: currencyFormatter,
      total: [],
      editableRows: [],
      approvals: [],
      metaInfo: [],
      metaInfoTextKeys: {
        report_job_id: 'Report job id',
        datetime_generated: 'Datetime generated',
        datetime_uploaded: 'Datetime uploaded',
        publisher_network_name: 'Publisher network name',
        adsense_user: 'Adsense user',
        adsense_name: 'Adsense name',
      },
      breadcrumbItems: [
        {
          text: 'adsense-incomes.title',
          active: true,
        },
      ],
      fields: [
        'check',
        { key: 'phoenix_site_domain', label: 'Site domain' },
        { key: 'date', label: 'Date', class: 'date-cell' },
        { key: 'clicks', label: 'Clicks' },
        { key: 'impressions', label: 'Impressions' },
        { key: 'revenue', label: 'Adsense income' },
        { key: 'income', label: 'Phoenix income' },
        { key: 'site_income', label: 'Site revenue' },
        { key: 'phoenix_revenue', label: 'Phoenix revenue' },
        { key: 'notes', label: 'Notes', class: 'text-center' },
        'edit',
      ],
      selectAll: false,
      currencies: [],
    }
  },
  computed: {
    ...mapGetters('dashboard-incomes', [
      'getImportIncomes',
      'getImportIncomesRows',
    ]),
    calcPhoenixRevenueTotal() {
      // if (Object.keys(this.totals).length) {
      //   // const phoenixIncomeTotal = this.totalAmount('income') // total incomes $
      //   Object.keys(this.totals).forEach(currencyKey => {
      //     const currencyObj = this.currencies.find(c => c.name === currencyKey)
      //     const siteIncomeTotal = Object.values(this.totals[currencyKey]).reduce((accumulator, currentValue) => {
      //       accumulator += currentValue
      //       console.log(currencyObj, accumulator)
      //       return accumulator
      //     })
      //     console.log('siteIncomeTotal', )
      //   //  const siteIncomeUSD = siteCurrency !== 'USD' ? siteIncome / currencyObj.rate : siteIncome
      //   // const phoenixIncome = Object.values(this.totals[currencyKey]).reduce((accumulator, currentValue) => accumulator + currentValue.income)
      //   // const sum = Object.values(this.totals[currencyKey]).reduce((accumulator, currentValue) => accumulator + +currentValue[value], initialValue)
      //   })
      // }
      return ''
    },
  },
  created() {
    this.getCurrencies()
  },
  methods: {
    async getCurrencies() {
      const responseData = await useJwt.currencies(this.$route.params.id)
      this.currencies = responseData.data.currencies || []
    },
    changeSiteIncome(input, row) {
      const currentRowInput = document.querySelector(`#adsense_incomes__details_${row.index}_ .form-control`)
      const currencyObj = this.currencies.find(c => c.name === row.item.site_currency)
      const siteIncomeUSD = row.item.site_currency !== 'USD' ? input / currencyObj.rate : input

      if (siteIncomeUSD <= row.item.income) {
        currentRowInput.classList.remove('is-invalid')
        row.item.site_income = input
      } else {
        currentRowInput.classList.add('is-invalid')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'SITE INCOME may not exceed PHOENIX INCOME',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
    setRowsSelected() {
      this.editableRows.forEach((item, idx) => {
        if (item.ok) {
          this.$refs.selectableTable.selectRow(idx)
        } else {
          this.$refs.selectableTable.unselectRow(idx)
        }
      })
    },
    onRowClick(item) {
      item.ok = !item.ok
    },
    async approveIncomes() {
      const objToSend = {
        original: this.getImportIncomes.rows,
        approve: this.editableRows,
      }
      Object.keys(this.getImportIncomes).forEach(key => {
        if (key !== 'rows') {
          objToSend[key] = this.getImportIncomes[key]
        }
      })

      const sendData = JSON.parse(JSON.stringify(objToSend))

      await useJwt.approveAdsenseIncomes(sendData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'ListIcon',
              variant: 'success',
            },
          })
          this.editableRows = []
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors[0].detail,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
    toggleSelected(event) {
      if (event) {
        this.$refs.selectableTable.selectAllRows()
        this.editableRows.forEach(item => {
          item.ok = true
        })
      } else {
        this.$refs.selectableTable.clearSelected()
        this.editableRows.forEach(item => {
          item.ok = false
        })
      }
    },
    onRowSelected(items) {
      this.approvals = items
      if (items.length === this.getImportIncomesRows.length) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
    },
    async uploadAdsenseIncomes(file) {
      const formData = new FormData()
      const fileList = file
      fileList.map(f => formData.append('files', f, f.name))
      await store.dispatch('dashboard-incomes/fetchIncomes', formData)
      const responseRows = JSON.parse(JSON.stringify(this.getImportIncomesRows))
      if (responseRows.length > 0) {
        this.editableRows = responseRows
        this.addTotals()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'ListIcon',
            variant: 'success',
          },
        })
        document.querySelector('.form-control-file').value = ''

        setTimeout(() => {
          this.setRowsSelected()
        }, 500)
      }
    },
    addTotals() {
      this.totals = this.reGroup(this.editableRows, 'site_currency')
    },
    reGroup(data, property) {
      return data.reduce((acc, obj) => {
        const key = obj[property]
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
    },
    countCurencyTotal(currencyKey, value) {
      const initialValue = 0
      if (Object.keys(this.totals[currencyKey]).length) {
        const sum = Object.values(this.totals[currencyKey]).reduce((accumulator, currentValue) => accumulator + +currentValue[value], initialValue)
        return this.currency(currencyKey, sum)
      }
      return 0
    },
    totalAmount(key) {
      let totalAmount = 0
      this.editableRows.forEach(i => {
        totalAmount += i[key]
      })
      return totalAmount
    },
    calcPhoenixRevenue(item) {
      const { site_currency: siteCurrency, income, site_income: siteIncome } = item
      let phoenixRevenue = 0
      const currencyObj = this.currencies.find(c => c.name === siteCurrency)
      if (currencyObj === undefined) {
        return null
      }
      const siteIncomeUSD = siteCurrency !== 'USD' ? siteIncome / currencyObj.rate : siteIncome
      if (income !== 0 && income >= siteIncomeUSD) {
        phoenixRevenue = this.currency(siteCurrency, (income - siteIncomeUSD) * currencyObj.rate)
      }
      return phoenixRevenue
    },
  },
}
</script>
<style scoped>
  .alert-body {
    font-weight: 400;
  }
  .checked {
    color: #28c76f;
  }
</style>
<style>
  [dir] table#adsense_incomes th.date-cell {
    min-width: 135px;
  }
</style>
